<template>
  <!-- App Header -->
    <div class="appHeader text-light" :class="'bg-'+color">
        <div class="left" v-if="menu">
            <router-link to="/" class="headerButton" data-toggle="modal" data-target="#sidebarPanel">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
            </router-link>
        </div>
        <div class="left" v-if="back">
            <button class="headerButton btn btn-link" @click="$router.back()">
                <svg viewBox="0 0 24 24" width="24" height="24" :stroke="(color=='white') ? '#000' : '#fff'" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="15 18 9 12 15 6"></polyline></svg>
            </button>
        </div>
        <div class="pageTitle" :class="{'text-dark':color=='white'}">
            {{title}}
        </div>
        <div class="right">
            <button class="btn btn-link" @click="logout()">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
            </button>
            <!-- <a href="app-notifications.html" class="headerButton">
                <ion-icon class="icon" name="notifications-outline"></ion-icon>
                <span class="badge badge-danger">4</span>
            </a> -->
            <!-- <a href="app-settings.html" class="headerButton">
                <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged w32">
                <span class="badge badge-danger">6</span>
            </a> -->
        </div>
    </div>
</template>

<script>
export default {
    props:{
        title:String,
        menu:Boolean,
        back:Boolean,
        color:{
            default:'primary',
            type:String
        }
    },
    methods:{
        logout(){
            localStorage.clear()
            location.href="/login"
        }
    }
}
</script>

<style>

</style>