const APP_DATA = {
    name:'bromatologia',
    env:'develop',
    platform_module: "BromatologiaCheck", // null (genera un bypass en login para cualquier usuario en nómina) // comprueba en routes/index.js y en Login
    db_url:{
        production: 'https://fs-mantenimiento.firebaseio.com',
        develop: 'https://bromatologia-fs.firebaseio.com'
    },
    db_config:{
        production: {
            apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
            authDomain: "cantina-89c6a.firebaseapp.com",
            databaseURL: "fs-mantenimiento.firebaseio.com",
            projectId: "cantina-89c6a",
            storageBucket: "cantina-89c6a.appspot.com",
            messagingSenderId: "353062217145",
            appId: "1:353062217145:web:8849a49a1ec52077"
        },
        develop: {
            apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
              authDomain: "cantina-89c6a.firebaseapp.com",
              databaseURL: "https://bromatologia-fs.firebaseio.com",
              projectId: "cantina-89c6a",
              storageBucket: "cantina-89c6a.appspot.com",
              messagingSenderId: "353062217145",
              appId: "1:353062217145:web:8849a49a1ec52077"
        }
    },
    
    url: 'https://bromatologia.foodservice.com.ar',
    description: 'Esta aplicación te permite generar auditorias, ver reportes y realizar seguimiento e las mismas.',

    api(){
        return this.db_url[this.env]
    }
}

export default APP_DATA;