<template>
    <HeaderLayout title="Auditoría en curso" :back="false" color="warning" v-if="trigger.firstStep && !trigger.end && !trigger.saving"/>
    
    <div id="appCapsule">
      <div class="section mt-2" v-if="!trigger.firstStep && audit">
        <FirstStep @ended="setFirstCofig($event)"/>
      </div>

      <HeaderAuditInfo
        v-if="trigger.firstStep && !trigger.end && !trigger.saving && operation && audit" 
        :operation="operation"
        :start="result.start_at"
        :manager="result.operation_manager"
      />

      <!-- <button @click="test()">TEST</button> -->

      <div class="section mt-3" v-if="!current_request && trigger.firstStep && !trigger.end && !trigger.saving && audit">
        <div class="row">
            <div class="col-12">
                <div class="section-heading">
                    <h2 class="title">Bloques</h2>
                    <div class="font-weight-bold">{{completedRequests}}/{{Object.keys(audit.requests).length}} bloques completados</div>
                </div>
            </div>
        </div>

        <div class="row">
          <div class="col-6" v-for="(request,r,i) in audit.requests" :key="r">
            <CardRequest :data="request" :index="i" :state="getRequestState(r)" @click="current_request=r"/>
          </div>
        </div>

        <div class="fixed-bar">
          <div class="row">
              <div class="col">
                  <button @click.prevent="submit()" class="btn btn-lg btn-warning btn-block" :disabled="completedRequests != Object.keys(audit.requests).length">Finalizar auditoría</button>                
              </div>
          </div>
        </div>
      </div>

      <div class="section mt-3" v-if="current_request && trigger.firstStep && !trigger.end && !trigger.saving && audit">
        <div class="row">
            <div class="col-12">
                  <div class="section-heading">
                    <h2 class="title">
                        <img v-if="audit.requests[current_request].icon" :src="audit.requests[current_request].icon" style="width:24px;height:24px">
                        <svg v-else viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                        {{audit.requests[current_request].label}}
                    </h2>
                    <!-- <a href="app-transactions.html" class="link">View All</a> -->
                </div>
            </div>
            <div class="col-12">
              <RequestItems :items="audit.requests[current_request].fields" :is_zero="is_zero(current_request,audit.requests)" :non="non_compliance" :initial="(audit_result) ? audit_result[current_request] : null" @change="handleRequestChanges($event)"/>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col">
                <button @click.prevent="current_request=null" class="btn btn-lg btn-warning btn-block">Guardar bloque</button>                
            </div>
        </div>
        <!-- <div class="fixed-bar">
        </div> -->
      </div>

      <template v-if="trigger.end">
        <div class="splash-page mt-5 mb-5">
          <!-- <p class="w-75 mx-auto text-dark mt-5">Auditoría finalizada</p> -->

          <template v-if="result.result=='aprobado'">
            <div class="iconbox mb-3 bg-transparent" style="width:240px;height:240px">
                <svg viewBox="0 0 512 512" width="200" height="200" fill="#1DCC70"><path d="M256 352C293.2 352 319.2 334.5 334.4 318.1C343.3 308.4 358.5 307.7 368.3 316.7C378 325.7 378.6 340.9 369.6 350.6C347.7 374.5 309.7 400 256 400C202.3 400 164.3 374.5 142.4 350.6C133.4 340.9 133.1 325.7 143.7 316.7C153.5 307.7 168.7 308.4 177.6 318.1C192.8 334.5 218.8 352 256 352zM208.4 208C208.4 225.7 194 240 176.4 240C158.7 240 144.4 225.7 144.4 208C144.4 190.3 158.7 176 176.4 176C194 176 208.4 190.3 208.4 208zM304.4 208C304.4 190.3 318.7 176 336.4 176C354 176 368.4 190.3 368.4 208C368.4 225.7 354 240 336.4 240C318.7 240 304.4 225.7 304.4 208zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
            </div>
            <h1 class="mb-2 text-success">Auditoría aprobada</h1>
          </template>

          <template v-if="result.result=='condicional'">
            <div class="iconbox mb-3 bg-transparent" style="width:240px;height:240px">
                <svg viewBox="0 0 512 512" width="200" height="200" fill="#ffb400"><path d="M144.4 208C144.4 190.3 158.7 176 176.4 176C194 176 208.4 190.3 208.4 208C208.4 225.7 194 240 176.4 240C158.7 240 144.4 225.7 144.4 208zM368.4 208C368.4 225.7 354 240 336.4 240C318.7 240 304.4 225.7 304.4 208C304.4 190.3 318.7 176 336.4 176C354 176 368.4 190.3 368.4 208zM328 328C341.3 328 352 338.7 352 352C352 365.3 341.3 376 328 376H184C170.7 376 160 365.3 160 352C160 338.7 170.7 328 184 328H328zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464z"/></svg>
            </div>
            <h1 class="mb-2 text-warning">Aprobado condicional</h1>
          </template>

          <template v-if="result.result=='desaprobado'">
            <div class="iconbox mb-3 bg-transparent" style="width:240px;height:240px">
                <svg viewBox="0 0 512 512" width="200" height="200" fill="#ff396f"><path d="M143.9 398.6C131.4 394.1 124.9 380.3 129.4 367.9C146.9 319.4 198.9 288 256 288C313.1 288 365.1 319.4 382.6 367.9C387.1 380.3 380.6 394.1 368.1 398.6C355.7 403.1 341.9 396.6 337.4 384.1C328.2 358.5 297.2 336 256 336C214.8 336 183.8 358.5 174.6 384.1C170.1 396.6 156.3 403.1 143.9 398.6V398.6zM208.4 208C208.4 225.7 194 240 176.4 240C158.7 240 144.4 225.7 144.4 208C144.4 190.3 158.7 176 176.4 176C194 176 208.4 190.3 208.4 208zM304.4 208C304.4 190.3 318.7 176 336.4 176C354 176 368.4 190.3 368.4 208C368.4 225.7 354 240 336.4 240C318.7 240 304.4 225.7 304.4 208zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>

            </div>
            <h1 class="mb-2 text-danger">Auditoría desaprobada</h1>
          </template>

          <button class="mx-auto w-75 mt-2 btn btn-md btn-link" v-if="element_to_print" @click="element_to_print.save()">
            Descargar resultados en formato PDF
            &nbsp;
            <svg fill="currentColor" viewBox="0 0 384 512" width="18" height="18" style="vertical-align: text-top;" data-v-05d61c56=""><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 232V334.1l31-31c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-72 72c-9.4 9.4-24.6 9.4-33.9 0l-72-72c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l31 31V232c0-13.3 10.7-24 24-24s24 10.7 24 24z" data-v-05d61c56=""></path></svg>
          </button>
      </div>
      <div class="fixed-bar">
          <div class="row">
              <div class="col">
                  <router-link v-if="!trigger['notifying']" to="/" class="btn btn-lg btn-primary btn-block">Finalizar</router-link>
                  <button class="btn btn-lg btn-primary btn-block" disabled v-else>
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    &nbsp;
                    Notificando los resultados
                  </button>
              </div>
          </div>
      </div>
      </template>

      <div id="loader" v-if="trigger.saving">
        <div class="text-center w-100">
          <img src="@/assets/img/bacteria_primary.png" alt="none" class="loading-icon mb-5">
          <h3 style="position: absolute;bottom: 0;width: 100%;margin-bottom: 5rem;" class="text-primary">{{saving_message}}</h3>
        </div>
      </div>
    </div>
</template>

<script>
import HeaderLayout from "./HeaderLayout"
import HeaderAuditInfo from "@/components/Audit/HeaderAuditInfo.vue";
import FirstStep from '@/components/Audit/FirstStep.vue'
import CardRequest from '@/components/Audit/CardRequest.vue'
import {getDataFrom,showError,setdefault,capitalize,isset} from '@/utils'
import RequestItems from '@/components/Audit/RequestItems.vue';

// import jsPDF from 'jspdf'
// import autoTable from 'jspdf-autotable'

import html2pdf from 'html-to-pdf-js'

import firebase from "firebase/compat/app";
import "firebase/compat/database";

import app from '@/env'

export default {
  components: {HeaderLayout,FirstStep,HeaderAuditInfo,CardRequest, RequestItems},
  data() {
    return {
      error:{operation:false},

      operation:null,
      audit:null,

      audit_result:null,

      current_request:null,

      non_compliance:null,

      result:{
        start_at:null,
        end_at:null,
        location:null,
        operation_manager:null,
      },

      result_to_draw:null,

      trigger:{
        firstStep:false,
        end:false,
        saving:false
      },

      zonal_mail:null,
      element_to_print:null,

      auditor:null,
    }
  },
  computed:{
    completedRequests(){
      let done = 0
      Object.keys(this.audit.requests).map((r)=>{
        if(this.getRequestState(r) != 'undone') done++
      })
      return done
    }
  },
  mounted() {
    getDataFrom('https://consumos.foodservice.com.ar/api/empresas').then((data)=>{
      this.operation = data.find((o)=>o.id==this.$route.params.id)

      getDataFrom('audits/-NFsMQHlhaeoxaE2IK3Z',true).then((data)=>{
        this.audit = data

        fetch(`${app.db_url[app.env]}/audits_in_progress/${this.operation.id}/-NFsMQHlhaeoxaE2IK3Z.json`)
        .then((r)=>r.json())
        .then((data)=>{
          // console.log('in prgress',data);
          if(data!=null){
            this.result = data
            if(data.requests) this.audit_result = data.requests
            this.trigger.firstStep = true
          }

          fetch(`${app.db_url[app.env]}/non_compliance/${this.operation.id}.json`)
          .then((r)=>r.json())
          .then((non)=>{
            console.log('in non compliance',non);
            this.non_compliance = non;


            // Get zonal mail
            let current_legajo = this.$route.params.legajo
            let dni = null
            getDataFrom('https://apprrhh-707b9.firebaseio.com/personal.json').then((data)=>{
              Object.keys(data).some((e_id)=>{
                if(data[e_id].leg_numero == current_legajo) {
                  dni = data[e_id].leg_numdoc;
                  this.set_zonal_mail(dni)
                  return true
                }
              })

              if(dni == null){
                getDataFrom('https://apprrhh-707b9.firebaseio.com/personal_cat.json').then((data)=>{
                  Object.keys(data).some((e_id)=>{
                    if(data[e_id].leg_numero == current_legajo) {
                      dni = data[e_id].leg_numdoc;
                      this.set_zonal_mail(dni)
                      return true
                    }
                  })
                })
              }
            })



          })


        })


      })
    })

    let user = JSON.parse(localStorage.userData)

    getDataFrom('https://bromatologia-fs.firebaseio.com/auditors.json?orderBy="dni"&equalTo="'+user.leg_numdoc+'"&limitToFirst=1').then((data)=>{
      // console.log('https://bromatologia-fs.firebaseio.com/auditors.json?orderBy="dni"&equalTo="'+user.leg_numdoc+'"&limitToFirst=1');
    //  console.log('user',data);
     if(data){
      this.auditor = data[Object.keys(data)[0]]
     }else {
      showError('AUDITOR NOT FOUND',"No encontramos tus datos en nuestros registros")
     }
    })
  },
  methods: {
    set_zonal_mail(dni){
      getDataFrom('https://consumos.foodservice.com.ar/api/usuarios').then((data)=>{
        Object.keys(data).some((d)=>{
          if(data[d].dni == dni) {
            this.zonal_mail = data[d].email
            return true
          }
        })
      })
    },
    is_zero(request_id,requests){
      return Object.keys(requests)[0] == request_id
    },
    setFirstCofig(data){
      this.result.operation_manager = data.manager
      this.result.location = data.location

      this.result.start_at = Date.now()

      // We need to update audits_in_progress to add this new audit
      firebase.database().ref(`audits_in_progress/${this.operation.id}/-NFsMQHlhaeoxaE2IK3Z/`).update(this.result)
      .then(()=>{
        // console.log('audit stated');
      }).catch((e)=>showError(e,'No pudimos iniciar la auditoría'))

      this.trigger.firstStep = true
    },
    handleRequestChanges(changes){
      if(this.audit_result == null) this.audit_result = {}
      this.audit_result[this.current_request] = changes

      firebase.database().ref(`audits_in_progress/${this.operation.id}/-NFsMQHlhaeoxaE2IK3Z/requests/${this.current_request}`).update(this.audit_result[this.current_request]).then(()=>{
        // console.log('audit updated');
      }).catch((e)=>showError(e,'No pudimos actualizar la información del bloque'))
    },
    getRequestState(r){
      
      if(this.audit_result==null || this.audit_result[r] == undefined) return 'undone'

      let total_score = 0
      let current_score = 0

      let undone = Object.keys(this.audit.requests[r].fields).some((f)=>{
        let answer = this.audit_result[r][f]
        console.log("aprovados")
        if (answer!=undefined) {
          if(!answer.approved && !answer.not_apply && !answer.disapproved){
            return true
          }


        total_score += parseInt(this.audit.requests[r].fields[f].score)


        if(answer.approved || answer.not_apply){
          current_score += parseInt(this.audit.requests[r].fields[f].score)
        }

         }
      })

      // console.log(current_score,total_score)

      if(undone) return 'undone'

      let total = current_score/total_score
      // console.log('total',total)
      if(total>=.6) return 'approved'
      return 'disapproved'
    },
    // test(){
    //   Object.keys(this.audit_result).forEach((r)=>{
    //     Object.keys(this.audit_result[r]).forEach((f)=>{
    //       console.log(this.get_item_result(this.audit_result[r][f]))
    //     })
    //   })
    // },
    submit(){
      this.trigger.saving = true
      this.saving_message = "Comprobando auditoría"
      this.trigger['notifying'] = true
      // console.log('se va a comprobar la auditoría para conocer el puntaje')
      // console.log('audit_result',this.audit_result)

      // Object.keys(this.audit)
      // console.log('audit',this.audit)

      let result = {}
      let priorities = {}
      let priorities_result = {}
      let non_compliance = {}

      /*eslint-disable*/
      let non_compliance_approved = null


     Object.keys(this.audit.requests).forEach((r) => {
  setdefault(result, r, { fields: {}, score: 0, label: null });

  let score = 0;
  let total = 0;

  Object.keys(this.audit_result[r]).forEach((f) => {
    setdefault(result[r].fields, f, {});

    let answer = this.get_item_result(this.audit_result[r][f]);

    if (this.audit.requests[r].is_zero) {
      non_compliance_approved = answer;
    }

    if (this.audit.requests[r].fields[f] && this.audit.requests[r].fields[f].score != undefined) {
      total += parseInt(this.audit.requests[r].fields[f].score);

      if (answer == 'approved' || answer == 'not_apply') {
        score += parseInt(this.audit.requests[r].fields[f].score);
      } else {
        setdefault(non_compliance, r, { label: this.audit.requests[r].label, fields: {} });
        non_compliance[r].fields[f] = {
          label: this.audit.requests[r].fields[f].label,
          observation: isset(this.audit_result[r][f].observation) ? this.audit_result[r][f].observation : null,
        };
      }

      // Information to print later this audit

      result[r].label = this.audit.requests[r].label;
      result[r].priority = this.audit.requests[r].priority;

      result[r].fields[f] = {
        label: this.audit.requests[r].fields[f].label,
        observation: isset(this.audit_result[r][f].observation) ? this.audit_result[r][f].observation : null,
        answer: answer,
        score: parseInt(this.audit.requests[r].fields[f].score),
      };
    }
  });

  // console.log(r,'score',score,'total',total,'result',score/total)
  result[r].score = total > 0 ? score / total : 0;

  setdefault(priorities, this.audit.requests[r].priority, 0);
  priorities[this.audit.requests[r].priority] += 1;

  setdefault(priorities_result, this.audit.requests[r].priority, 0);

  if (result[r].score >= 0.6) {
    priorities_result[this.audit.requests[r].priority] += 1;
  }
});




      // console.log(result)
      // console.log(priorities)
      // console.log(priorities_result)
      // console.log(non_compliance)

      // Non compliance set approved
      if(this.non_compliance){
        if (non_compliance_approved == 'approved' || non_compliance_approved == 'disapproved'){
          let non_key = Object.keys(this.non_compliance)[0]
          this.non_compliance[non_key]['state'] = non_compliance_approved
          this.non_compliance[non_key]['close_date'] = Date.now()
  
          firebase.database().ref(`non_compliance/${this.operation.id}/${non_key}`).update(this.non_compliance[non_key]).then(()=>{
            console.log('non_compliance updated');
          }).catch((e)=>showError(e,'Error al actualizar el estado de incumplimientos previos.'))
        }
      }

      let state = ''

      if(priorities[1] == priorities_result[1]){
        if(priorities[2] == priorities_result[2] && priorities[3] == priorities_result[3]){
          // Todos los bloques aprobados + los bloques de 4ta prioridad desaprobado
          state = 'aprobado'
        } else if(priorities[2] == priorities_result[2] && priorities[3] != priorities_result[3]) {
          // El bloque de 3era prioridad desaprobado
          state = 'condicional'
        } else if((priorities[2]-priorities_result[2])==1){
          // El bloque de 3era prioridad desaprobado + 1 bloque de 2da prioridad desaprobado
          state = 'condicional'
        } else {
          // Bloques de 2da prioridad 2 o + desaprobados
          state = 'desaprobado'
        }
      }else {
        // Bloques de 1era prioridad 1 o + desaprobados
        state = 'desaprobado'
      }

      this.result['requests'] = result
      this.result['result'] = state
      // this.result['priorities_result'] = priorities_result
      this.result['operation_id'] = this.operation.id
      this.result['operation_name'] = this.operation.name
      this.result.end_at = Date.now()  

      let user = JSON.parse(localStorage.getItem('userData'))

      this.result['auditor'] = {
        name:user.leg_apellido + " " + user.leg_nombre,
        dni:user.leg_numdoc,
        legajo: user.leg_numero
      }







  // Notify by email
            let state_color = {
              'aprobado': 'verde',
              'condicional': 'amarillo',
              'desaprobado': 'rojo'
            }

            let state_hex_color = {
              'aprobado': '#00ff28',
              'condicional': '#ffcb00',
              'desaprobado': '#ff2400'
            }




      let header_mail = `<h4>Resultado: <span style="background:${state_hex_color[state]}">${capitalize(state_color[state])}</span></h4>`
      header_mail += `<p>Responsable en operación ${this.operation.name}: ${this.result.operation_manager.name} (${this.result.operation_manager.position}), DNI ${this.result.operation_manager.dni}</p>`
      header_mail += `<p>Auditor ${user.leg_apellido + " " + user.leg_nombre}</p>`

      this.get_result_as_table(result)

      let element = document.createElement('div')
      element.innerHTML = header_mail + this.get_result_as_table(result)
      element.style.color = "#000";

      // console.log(element);

      // html2pdf(pddf)

      let date_string = new Date().toLocaleString().split(',')[0];

      var opt = {
        margin:       8,
        filename:     `auditoria_${this.operation.name.toLowerCase().replaceAll(' ','_')}_${date_string}.pdf`,
        html2canvas:  { scale: 1 },
      };

      this.element_to_print = html2pdf().set(opt).from(element)
       
      // New Promise-based usage:
      // html2pdf().set(opt).from(element).save();

      // this.trigger.saving = false
      // this.trigger.end = true



      // return null;




      // time to wait until change the saving_message
      setTimeout(() => {
        this.saving_message = "Guardando información"

        var completedAuditRef = firebase.database().ref('audits_completed').push();
        let audit_id = completedAuditRef.key;
        
        completedAuditRef.update(this.result).then(()=>{
          console.log('%cthe audit was successfully saved','color:#0f0');

          firebase.database().ref(`audits_in_progress/${this.operation.id}`).remove().then(()=>{
            // Notify by email
            let state_color = {
              'aprobado': 'verde',
              'condicional': 'amarillo',
              'desaprobado': 'rojo'
            }

            let state_hex_color = {
              'aprobado': '#00ff28',
              'condicional': '#ffcb00',
              'desaprobado': '#ff2400'
            }

            // let header_mail = `<h4>Resultado: ${(state=='condicional')?'Aprobado condicional':capitalize(state)}</h4>`
            let header_mail = `<h4>Resultado: <span style="background:${state_hex_color[state]}">${capitalize(state_color[state])}</span></h4>`
            header_mail += `<p>Responsable en operación ${this.operation.name}: ${this.result.operation_manager.name} (${this.result.operation_manager.position}), DNI ${this.result.operation_manager.dni}</p>`
            header_mail += `<p>Auditor ${user.leg_apellido + " " + user.leg_nombre}</p>`



            // let table = document.createElement('table');
            // table.innerHTML = this.get_result_as_table(result)

            // const doc = new jsPDF()
            // doc.text("Hello world!", 10, 10);
            // // It can parse html:
            // // <table id="my-table"><!-- ... --></table>
            // autoTable(doc, { html: table ,theme:'grid'})

            // doc.save('table.pdf')

            // return null;


            fetch('https://consumos.foodservice.com.ar/api/enviomail', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(
                  {
                      from: "avisos@foodservice.com.ar",
                      to: 'sswatowski@foodservice.com.ar',
                      // to: 'yamil.vernet@gmail.com',
                      subject: `Resultados de auditoría en ${this.operation.name}`,
                      body: header_mail + this.get_result_as_table(result),
                  }
              ),
              })
              .then((response) => response.text())
              .then((data) => {
                  console.log('Success:', data);

                  // Mail to zonal
                  fetch('https://consumos.foodservice.com.ar/api/enviomail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(
                        {
                            from: "avisos@foodservice.com.ar",
                            // to:' yvernet@foodservice.com.ar',
                            to: this.zonal_mail,
                            subject: `Resultados de auditoría en ${this.operation.name}`,
                            body: header_mail + this.get_result_as_table(result),
                        }
                    ),
                    })
                    .then((response) => response.text())
                    .then((data) => {
                        console.log('Success:', data);


                        // Mail to auditor

                        fetch('https://consumos.foodservice.com.ar/api/enviomail', {
                          method: 'POST',
                          headers: {
                              'Content-Type': 'application/json',
                          },
                          body: JSON.stringify(
                              {
                                  from: "avisos@foodservice.com.ar",
                                  // to:' yvernet@foodservice.com.ar',
                                  to: this.auditor.mail.toLowerCase(),
                                  subject: `Resultados de auditoría en ${this.operation.name}`,
                                  body: header_mail + this.get_result_as_table(result),
                              }
                          ),
                          })
                          .then((response) => response.text())
                          .then((data) => {
                              console.log('Success:', data);
                              this.trigger['notifying'] = false
                          })
                          .catch((error) => {
                              console.error('Error:', error);
                              alert('Error: No pudimos notificar. Vuelva a intentar en unos minutos')
                              return null
                          });





                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        alert('Error: No pudimos notificar. Vuelva a intentar en unos minutos')
                        return null
                    });



              })
              .catch((error) => {
                  console.error('Error:', error);
                  alert('Error: No pudimos notificar. Vuelva a intentar en unos minutos')
                  return null
              });
            
            // Existen no conformidades
            if(Object.keys(non_compliance).length != 0){
              firebase.database().ref('non_compliance/'+this.operation.id).push({
                audit_id: audit_id,
                operation_id: this.operation.id,
                timestamp: this.result.end_at,
                requests:non_compliance
              }).then(()=>{
                this.trigger.saving = false
                this.trigger.end = true
                console.log('todo listo, se guardo la auditoria y las no conformidades');
                
                // TODO eliminar in progress o meterle fecha de finalización
    
              }).catch((err)=>showError(err,'Error al guardar no conformidades'))
            } else {
              this.trigger.saving = false
              this.trigger.end = true
              console.log('todo listo, se guardo la auditoria');
            }
          }).catch((err)=>showError(err,'Error al eliminar la auditoria en progreso'))
        }).catch((err)=>showError(err,'Error al guardar la auditoria'))
  
      }, 1500);

      
    
    },
    get_item_result(item){
      return Object.keys(item).filter((k)=>k!='observation' && item[k])[0]
    },
    get_result_as_table(requests){
      let labels = {
          'not_apply':'No aplica',
          'approved':'Sí',
          'disapproved': 'No'
      }


      let result = ""

      result += "<table border='1' style='margin: 5px 0;width:100%'>"

      result += '<tr>'
      result += `<th>Campo</th>`
      result += `<th>Cumple</th>`
      result += `<th>Observación</th>`
      result += '</tr>'

      Object.keys(requests).forEach((r,i)=>{
          result += '<tr>'
          result += `<td colspan="3" style="font-weight:bold;padding: 15px 5px;">BLOQUE ${i+1}: ${requests[r].label}</td>`
          result += '</tr>'

          Object.keys(requests[r].fields).forEach((f)=>{
              result += "<tr>"
              
              result += `<td style='padding: 5px;'>${requests[r].fields[f].label}</td>`
              result += `<td style='padding: 5px;'>${labels[requests[r].fields[f].answer]}</td>`

              if(requests[r].fields[f].observation){
                  result += `<td style='padding: 5px;'>${requests[r].fields[f].observation}</td>`
              } else {
                  result += "<td style='padding: 5px;'></td>"
              }

              result += "</tr>"
          })

          result += '<tr>'
          result += `<td colspan="3" style="font-weight:bold;padding: 15px 5px;">&nbsp;</td>`
          result += '</tr>'
      })

      result += "</table>"

      return result
    },
    capitalizeR(s){return capitalize(s)}
  }
}
</script>

<style scoped>
.wallet-card-section::before {
  background: #FFB400;
}

#loader{
  background:none
}

#loader .loading-icon {
  width: 42px;
}

.blured {
  opacity: .55;
}
</style>