<template>
  <div id="appCapsule">

      <div class="section mt-2 text-center">
          <h1>Ingresar</h1>
          <h4>Check de Bromatología</h4>
      </div>

      <div class="section my-4" v-if="error['permission']">
        <div class="alert alert-danger">
            No dispone de permisos suficientes para acceder a la plataforma
        </div>
      </div>

      <div class="section mt-2 mb-5">
          <form @submit.prevent="login($event)">
            <div class="card">
                <div class="card-body">
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label" for="dni">Documento</label>
                            <input type="text" class="form-control" id="dni" placeholder="23456789" @input="delete error['dni']">
                            <i class="clear-input">
                                <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                            </i>
                            <div class="text-muted text-danger" v-if="error['dni']">&otimes; {{error['dni']}}</div>
                        </div>
                    </div>
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="label" for="password">Contraseña</label>
                            <input type="password" class="form-control" id="password" placeholder="••••••••••" @input="delete error['password']">
                            <i class="clear-input">
                                <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                            </i>
                            <div class="text-muted text-danger" v-if="error['password']">&otimes; {{error['password']}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center mt-2">
                <div><a href="app-forgot-password.html">Olvide mi contraseña</a></div>
            </div>

            <div class="form-button-group">
                <button type="submit" name="button" class="btn btn-primary btn-block btn-lg">Ingresar</button>
            </div>

          </form>
      </div>

  </div>
</template>

<script>
import APP_DATA from '@/env';
// import APP_DATA from '@/env'
export default {
    data() {
        return {
            error:{}
        }
    },
    methods: {
        login(event){
            let value = {
                dni:event.target.dni.value.trim(),
                password:event.target.password.value.trim()
            }

            // Se comprueba que haya ingresado un dni, compuesto solo de numeros y una contraseña
            if(!this.valid(value)) return null

            // Check credentials
            this.formTrigger(event.target)

            fetch('https://auth.foodservice.com.ar/?type=login&user='+value.dni+'&access_token=1234567')
            .then((r)=>r.json())
            .then((data)=>{
                // console.log(data);
                delete this.error['permission'] // Se quita el alert en la vista

                // El dni no existe
                if(data[0].datos == null){
                    this.formTrigger(event.target)
                    this.error['dni'] = 'Compruebe el documento, no es correcto'
                    return
                }


                let user = data[0]

                // Comprueba si el usuario no tiene un password generado
                if(user.Password[0].contrasena == null){
                    // Se crea un pass temporal con la fecha de nacimiento
                    var temp_password = user.datos.leg_fecnac .replace(" 00:00:00", "") .split("-")
                    temp_password = temp_password[2] + temp_password[1] + temp_password[0]

                    // Si ingresa mal la fecha de nacimiento, se verifica si ingresa con 123456
                    if(temp_password != value.password && value.password != "123456"){
                        // Si no ingreso con ningún metodo, esta usando una contraseña incorrecta
                        this.formTrigger(event.target)
                        this.error['password'] = 'Compruebe la contraseña, no es correcta'
                        return
                    }

                // Si tiene password se comprueba que lo haya ingresado mal
                } else if(user.Password[0].contrasena != value.password){
                    this.formTrigger(event.target)
                    this.error['password'] = 'Compruebe la contraseña, no es correcta'
                    return
                }

                // Llegado a este paso ingreso un password correcto, se verifica el permiso
                let hasPermission = true
                // Si la plataforma no es cerrada, como alimentacion que puede entrar cualquiera en nomina
                if(APP_DATA.platform_module != null){
                    // Se busca el permiso de la plataforma
                    hasPermission = user.Permisos.some((p)=> p.ModulosAccess == APP_DATA.platform_module)
                }

                // Si no tiene permiso se niega el acceso mostrando un cartel mediante la propiedad error.permission
                if(!hasPermission){
                    this.formTrigger(event.target)
                    this.error['permission'] = true
                    return
                }

                // Todo salio bien, se procede a establecer data local y continuar al home
                localStorage.setItem("userPermisos", JSON.stringify(user.Permisos))
                localStorage.setItem("userData", JSON.stringify(user.datos))

                this.$router.push('/')
            })
        },
        formTrigger(form){
            let state = true
            if(form.button.disabled == true) state = false

            form.dni.disabled = state;
            form.password.disabled = state;
            form.button.disabled = state;
            if(state){
                form.button.innerHTML = `
                    <div class="spinner-border" style="width:12px;height:12px" role="status">
                        <span class="sr-only">Loading...</span>
                    </div> &nbsp; Comprobando
                `
            } else {
                form.button.innerHTML = 'Ingresar'
            }
        },
        valid(values){
            let v = true;
            if(values.dni == null || values.dni == ''){
                this.error['dni'] = 'El campo es obligatorio'
                v = false
            }

            if(values.password == null || values.password == ''){
                this.error['password'] = 'El campo es obligatorio'
                v = false
            }

            if(! /^\d+$/.test(values.dni)){
                this.error['dni'] = 'Ingrese solo números'
                v = false
            }

            return v
        },

    },
};
</script>

<style>
</style>