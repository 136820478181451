<template>
  <HeaderLayout title="No conformidades" color="white" style="color:black" :back="true"/>

  <div id="appCapsule" v-if="completed_audit">
    <template v-for="(audit,a) in non_compliance" :key="a">
        <div class="section mt-3">
            <div class="section-heading" @click="handleSelect(a)">
                <h3 class="title">
                    <svg width="16" height="16" style="vertical-align: baseline;" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M152 64H296V24C296 10.75 306.7 0 320 0C333.3 0 344 10.75 344 24V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H104V24C104 10.75 114.7 0 128 0C141.3 0 152 10.75 152 24V64zM48 448C48 456.8 55.16 464 64 464H384C392.8 464 400 456.8 400 448V192H48V448z"/></svg>
                    Auditoría {{dateFormateR(audit.timestamp)}}
                </h3>
                <svg v-if="selected!=a" viewBox="0 0 24 24" width="24" height="24" stroke="#000" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="6 9 12 15 18 9"></polyline></svg>
                <svg v-else viewBox="0 0 24 24" width="24" height="24" stroke="#000" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="18 15 12 9 6 15"></polyline></svg>
            </div>
        </div>

        <div v-if="selected==a">
            <div class="mb-2" v-for="(request,r) in audit.requests" :key="r">
                <div class="listview-title">
                    <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                    {{completed_audit.requests[r].label}}
                </div>
                <ul class="listview mx-3 border-0 rounded">
                    <template v-for="(field,f) in request.fields" :key="f">
                        <li class="flex-nowrap">
                            <div class="w-100">
                                <div>{{field.label}}</div>
                                <div class="alert alert-secondary rounded mt-1" v-if="field.observation">
                                    Observación: {{field.observation}}
                                </div>

                                <h5 class="mt-2 mb-1">Acciones</h5>
                                <div class="border border-danger text-danger blured p-1 rounded" v-if="!request[f]">
                                    Aún no se tomaron acciones correctivas
                                </div>

                                <template v-else>
                                    <div 
                                        class="border rounded p-2 mb-1" 
                                        :class="{
                                            'bg-success blured':request[f].response.inmediate_status=='accepted',
                                            'bg-danger blured':request[f].response.inmediate_status=='rejected'
                                        }"
                                        v-if="request[f].response.inmediate" 
                                    >
                                        <b class="mb-1">Acción correctiva (AC)</b>
                                        <br>
                                        {{request[f].response.inmediate}}
                                        <div v-if="request[f].response.inmediate_files">
                                            <div class="btn btn-link btn-link-primary mr-1 px-0" @click="show_modal_file(file)" v-for="(file,fl) in request[f].response.inmediate_files" :key="fl">
                                                <svg viewBox="0 0 384 512" width="12" heihgt="12" fill="currentColor"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 64C0 28.65 28.65 0 64 0H229.5C246.5 0 262.7 6.743 274.7 18.75L365.3 109.3C377.3 121.3 384 137.5 384 154.5V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM336 448V160H256C238.3 160 224 145.7 224 128V48H64C55.16 48 48 55.16 48 64V448C48 456.8 55.16 464 64 464H320C328.8 464 336 456.8 336 448z"/></svg>
                                                &nbsp;
                                                Adjunto {{fl+1}}
                                            </div>
                                        </div>
                                        <br>
                                        <div class="d-flex align-items-center justify-content-between mt-2">
                                            <small class="d-block">{{request[f].response.user.name}} {{dateFormateR(request[f].response.timestamp)}}</small>
                                            <div class="text-right" v-if="!request[f].response.inmediate_status">
                                                <button class="btn btn-sm btn-danger p-1 mr-2" @click="handleResponseStatus(a,r,f,'inmediate','rejected')">Rechazar</button>
                                                <button class="btn btn-sm btn-success p-1" @click="handleResponseStatus(a,r,f,'inmediate','accepted')">Aprobar</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        class="border rounded p-2 mb-1"    
                                        :class="{
                                            'bg-success blured':request[f].response.mediate_status=='accepted',
                                            'bg-danger blured':request[f].response.mediate_status=='rejected'
                                        }"
                                        v-if="request[f].response.mediate"
                                    >
                                        <b class="mb-1">Acción preventiva (AP)</b>
                                        <br>
                                        {{request[f].response.mediate}}
                                        <div v-if="request[f].response.mediate_files">
                                            <div class="btn btn-outline btn-outline-primary mr-1" v-for="(file,fl) in request[f].response.mediate_files" :key="fl">
                                                <svg viewBox="0 0 384 512" width="14" heihgt="14" fill="currentColor"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 64C0 28.65 28.65 0 64 0H229.5C246.5 0 262.7 6.743 274.7 18.75L365.3 109.3C377.3 121.3 384 137.5 384 154.5V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM336 448V160H256C238.3 160 224 145.7 224 128V48H64C55.16 48 48 55.16 48 64V448C48 456.8 55.16 464 64 464H320C328.8 464 336 456.8 336 448z"/></svg>
                                                Adjunto {{fl+1}}
                                            </div>
                                        </div>
                                        <br>
                                        <div class="d-flex align-items-center justify-content-between mt-2">
                                            <small class="d-block">{{request[f].response.user.name}} {{dateFormateR(request[f].response.timestamp)}}</small>
                                            <div class="text-right" v-if="!request[f].response.mediate_status">
                                                <button class="btn btn-sm btn-danger p-1 mr-2" @click="handleResponseStatus(a,r,f,'mediate','rejected')">Rechazar</button>
                                                <button class="btn btn-sm btn-success p-1" @click="handleResponseStatus(a,r,f,'mediate','accepted')">Aprobar</button>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </li>
                    </template>





                    <!-- <li class="flex-nowrap" v-for="(field,f) in request" :key="f">
                        <div class="w-100">
                            <pre>{{(request.fields[f])?request.fields[f].label:'no tiene f ' + f}}</pre>
                            <div class="alert alert-secondary rounded mt-1" v-if="field.observation">
                                {{field.observation}}
                            </div>
                            <h5 class="mt-2 mb-1">Acciones</h5>
                            <div class="alert alert-danger rounded blured" v-if="!field.response">
                                Aún no se tomó ninguna acción correctiva
                            </div>
                            <template v-else>
                                <div 
                                    class="border rounded p-2 mb-1" 
                                    :class="{
                                        'bg-success blured':field.response.inmediate_status=='accepted',
                                        'bg-danger blured':field.response.inmediate_status=='rejected'
                                    }"
                                    v-if="field.response.inmediate" 
                                >
                                    <b class="mb-1">Inmediata</b>
                                    <br>
                                    {{field.response.inmediate}}
                                    <br>
                                    <div class="d-flex align-items-center justify-content-between mt-2">
                                        <small class="d-block">{{field.response.user.name}} {{dateFormateR(field.response.timestamp)}}</small>
                                        <div class="text-right" v-if="!field.response.inmediate_status">
                                            <button class="btn btn-sm btn-danger p-1 mr-2" @click="handleResponseStatus(a,r,f,'inmediate','rejected')">Rechazar</button>
                                            <button class="btn btn-sm btn-success p-1" @click="handleResponseStatus(a,r,f,'inmediate','accepted')">Aprobar</button>
                                        </div>
                                    </div>
                                </div>
                                <div 
                                    class="border rounded p-2 mb-1"    
                                    :class="{
                                        'bg-success blured':field.response.mediate_status=='accepted',
                                        'bg-danger blured':field.response.mediate_status=='rejected'
                                    }"
                                    v-if="field.response.mediate"
                                >
                                    <b class="mb-1">Mediata</b>
                                    <br>
                                    {{field.response.mediate}}
                                    <br>
                                    <div class="d-flex align-items-center justify-content-between mt-2">
                                        <small class="d-block">{{field.response.user.name}} {{dateFormateR(field.response.timestamp)}}</small>
                                        <div class="text-right" v-if="!field.response.mediate_status">
                                            <button class="btn btn-sm btn-danger p-1 mr-2" @click="handleResponseStatus(a,r,f,'mediate','rejected')">Rechazar</button>
                                            <button class="btn btn-sm btn-success p-1" @click="handleResponseStatus(a,r,f,'mediate','accepted')">Aprobar</button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </li> -->
                </ul>
            </div>
        </div>

        <div class="modal fade modalbox show" id="Modal" tabindex="-1" style="display: block;" aria-modal="true" role="dialog" v-if="file_show">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Imagen adjunta</h5>
                        <a href="#" data-bs-dismiss="modal" @click="file_show=null">Cerrar</a>
                    </div>
                    <div class="modal-body">
                        <img class="w-100" :src="file_show">
                    </div>
                </div>
            </div>
        </div>

    </template>
  </div>
</template>

<script>
import {dateFormat} from '@/utils'
import APP_DATA from '@/env'

import firebase from "firebase/compat/app";
import "firebase/compat/database";

import HeaderLayout from "./HeaderLayout";
export default {
    name: "NonComplianceView",
    components: { HeaderLayout },

    data() {
        return {
            non_compliance:null,
            completed_audit:null,
            selected:null,
            file_show:null,
        }
    },
    mounted() {
        // No se usa getDataFrom ya que es necesario actualizar siempre la información
        fetch(`${APP_DATA.api()}/non_compliance/${localStorage.selected_operation}.json`).then((r)=>r.json())
        .then((non_data)=>{
            this.non_compliance = non_data
            let completed_audit_id = Object.keys(this.non_compliance).at(0)

            fetch(`${APP_DATA.api()}/audits_completed/${non_data[completed_audit_id].audit_id}.json`).then((r)=>r.json())
            .then((audit_data)=>{
                this.completed_audit = audit_data
            })
        })
    },
    methods: {
        show_modal_file(url){
            this.file_show=url
        },
        handleSelect(value){
            console.log(value);
            if(this.selected==value){
                this.selected = null
            } else {
                this.selected = value
            }
        },
        dateFormateR(d){return dateFormat(d)},
        handleResponseStatus(a,r,f,type,status){
            console.log(r,f,status);
            let p = `${type}_status`
            let o = {}
            o[p] = status
            firebase.database().ref(`non_compliance/${localStorage.selected_operation}/${a}/requests/${r}/${f}/response`).update(o).then(()=>{
                console.log(this.non_compliance[a].requests[r][f][p],status);
                console.log('en: ',this.non_compliance[a].requests,r,f,p)
                this.non_compliance[a].requests[r][f].response[p] = status
                this.$forceUpdate();
            })


        }
    },
};
</script>

<style scoped>
.wallet-card-section::before {
  background: #6236FF !important;
}

.blured {
    opacity: .6;
}
</style>
