<template>
    <div class="card mb-2" :class="{'bg-success text-white':state=='approved','bg-danger text-white':state=='disapproved','text-dark':state=='undone'}">
        <div class="card-body">
            <div class="d-flex align-items-center">
                <div class="mr-1">
                    <img v-if="data.icon" :src="data.icon" :class="{'invert-color':state!='undone'}" style="width:32px;height:32px">
                    <svg v-else viewBox="0 0 24 24" width="32" height="32" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg>
                </div>
                <div class="" style="line-height: 1.6">
                    <div style="font-size: 0.7rem;">Bloque {{index}}</div>
                    <div class="font-weight-bold" style="font-size: 0.8rem;">
                        {{data.label}}
                    </div>
                    <div style="font-size: 0.7rem;">{{(data.fields) ? Object.keys(data.fields).length : 0}} items</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        state:{
            type:String,
            default:'undone', 
            // possible states: 'undone' | 'approved' | 'dissaproved'
        },
        index:Number,
        data:Object
    }
}
</script>

<style>
.invert-color {
    filter:invert(100%)
}
</style>