<template>
  <HeaderLayout title="Bromatología" color="primary" :close="true"/>

  <div id="appCapsule">
    <div class="section wallet-card-section pt-1">
        <div class="row">
            <div class="col-12">
                <div class="wallet-card">
                    <!-- Balance -->
                    <div class="balance mb-0">
                        <div class="left">
                            <span class="title">{{ greeting() }}</span>
                            <h1 class="total">{{ name() }}</h1>
                        </div>
                        <div class="right">
                        </div>
                    </div>
                    <div>
                        <SelectOperation @selected="handleOperationSelection($event)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="section mt-2">
        <div class="row">
            <div class="col">
                <div class="alert alert-primary d-flex-c-s">
                    <svg class="mr-1" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                    <span>
                        Seleccione una operación para comenzar a ver auditorías.
                    </span>
                </div>
            </div>
        </div>
    </div> -->

    <div class="section mt-2">
        <div class="row">
            <div class="col-12">
                <div class="section-heading">
                    <h2 class="title">Auditorias</h2>
                </div>
            </div>
        </div>

        <div class="row" v-if="!operation">
            <div class="col-12">
                <div class="alert alert-primary d-flex-c-s">
                    <svg class="mr-1" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                    <span>
                        Seleccione una operación para comenzar a ver auditorías.
                    </span>
                </div>
            </div>
        </div>

        <div class="row mb-2" v-if="operation">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="">
                            <div class="d-flex-c-b py-2 border-bottom">
                                <span>Tipo de empresa</span>
                                <strong class="text-dark">{{operation.tipo_empresa}}</strong>
                            </div>
                            <div class="d-flex-c-b py-2 border-bottom">
                                <span>Zonal a cargo</span>

                                <div class="text-dark" v-if="!zonal">
                                    <div class="spinner-border" style="width:12px;height:12px" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>

                                <strong class="text-dark" v-else>{{zonal.nombre}}</strong>
                            </div>
                            <div class="d-flex-c-b py-2 border-bottom">
                                <span>Localidad</span>
                                <strong class="text-dark">{{operation.localidad}}</strong>
                            </div>
                            <div class="d-flex-c-b py-2 border-bottom">
                                <span>Dirección</span>
                                <div>
                                    <strong class="text-dark mr-1">{{operation.direccion}}</strong>
                                    <a target="_blank" :href="getMapsURL(operation)" class="btn btn-sm btn-success">
                                        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                                    </a>
                                </div>
                            </div>
                            <div class="d-flex-c-b py-2">
                                <span>Última auditoría</span>

                                <div class="spinner-border" v-if="last_audit == 'waiting'" style="width:12px;height:12px" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <strong class="text-dark" v-else>{{(last_audit == null) ? 'Sin registros' : dateFormateR(last_audit.end_at,last_audit)}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="operation">
                <div class="col-5 mb-2">
                    <router-link to="history" class="card blured">
                        <div class="card-body d-flex-c-b">
                            <h4 class="mb-0">Historial</h4>
                            <div class="bg-primary rounded p-1">
                                <svg width="24" height="24" fill="#fff" viewBox="0 0 512 512"><path d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9V168c0 13.3 10.7 24 24 24H134.1c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24V256c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65V152c0-13.3-10.7-24-24-24z"/></svg>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-7 mb-2">

                    <router-link :to="'non-compliance/'+operation.id" class="card" v-if="non_compliance">
                        <div class="card-body d-flex-c-b">
                            <h4 class="mb-0">No conformidades</h4>
                            <div class="bg-danger rounded p-1">
                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                            </div>
                        </div>
                    </router-link>

                    <div class="card blured" v-else>
                        <div class="card-body d-flex-c-b">
                            <h4 class="mb-0">No conformidades</h4>
                            <div class="bg-danger rounded p-1">
                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-12 mb-2">
                    <router-link :to="'auditoria/'+operation.id+'/'+zonal.legajo" class="card">
                        <div class="card-body d-flex-c-b">
                            <h4 class="mb-0" v-if="isInProgress">Continuar auditoría</h4>
                            <h4 class="mb-0" v-else>Nueva auditoría</h4>

                            <div class="bg-success rounded p-1">
                                <svg width="24" height="24" fill="#fff" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                            </div>
                        </div>
                    </router-link>
                </div>

            </div>
        </div>
        <!-- <div class="mx-3 mt-4">
            <button class="btn btn-primary" @click="test()">TEST</button>
        </div> -->
  </div>
</template>

<script>
import {getDataFrom,dateFormat} from '@/utils'

import HeaderLayout from "./HeaderLayout";
import SelectOperation from '@/components/Dashboard/SelectOperation.vue'
export default {
    name: "HomeView",
    components: { HeaderLayout,SelectOperation },


    data() {
        return {
            operation:null,
            operations:null,
            zonal:null,
            isInProgress:null,
            non_compliance:null,
            last_audit: 'waiting',

            // labels: {
            //     'not_apply':'No aplica',
            //     'approved':'Sí',
            //     'disapproved': 'No'
            // }
        }
    },
    mounted() {
        getDataFrom('https://consumos.foodservice.com.ar/api/empresas').then((data)=>{
            this.operations = data
        })
    },
    methods: {
        check_no_compliance(){
            // console.log(this.operation.id)
            fetch(`https://bromatologia-fs.firebaseio.com/non_compliance/${this.operation.id}.json?shallow=true`)
            .then((r)=>r.json())
            .then((data)=>{
                // console.log(data)
                this.non_compliance = data
            })
        },
        // test(){
        //     // console.log('si');

        //     fetch('https://bromatologia-5c2c2-default-rtdb.firebaseio.com/audits_completed/-NGwpoqpAuO3l2QZMdHz.json').then((r)=>r.json())
        //     .then((data)=>{
        //         console.log('tengo dateli',data);
        //         let requests = data.requests
        //         let result = ""


        //         //  <table>
        //         //     <tr>
        //         //         <th>Person 1</th>
        //         //         <th>Person 2</th>
        //         //         <th>Person 3</th>
        //         //     </tr>
        //         //     <tr>
        //         //         <td>Emil</td>
        //         //         <td>Tobias</td>
        //         //         <td>Linus</td>
        //         //     </tr>
        //         //     <tr>
        //         //         <td>16</td>
        //         //         <td>14</td>
        //         //         <td>10</td>
        //         //     </tr>
        //         // </table> 

        //         result += "<table border='1' style='margin: 5px 0;width:100%'>"

        //         result += '<tr>'
        //         result += `<th>Campo</th>`
        //         result += `<th>Cumple</th>`
        //         result += `<th>Observación</th>`
        //         result += '</tr>'

        //         Object.keys(requests).forEach((r,i)=>{

        //             // result += result

        //             result += '<tr>'
        //             result += `<td colspan="3" style="font-weight:bold;padding: 15px 5px;">BLOQUE ${i+1}: ${requests[r].label}</td>`
        //             result += '</tr>'

                    






        //             // intermediate_result += `<th>Prioridad: ${requests[r].priority}</th>`
        //             // intermediate_result += `<th>Obtenido: ${requests[r].score * 100}%</th>`


        //             // console.log(requests[r].label,requests[r].priority,requests[r].score * 100) 			
        //             // intermediate_result += "</table>"
        //             // result += intermediate_result

                    

        //             // intermediate_result = "<table border='1' style='margin-bottom: 10px;width:100%'>"

        //             Object.keys(requests[r].fields).forEach((f)=>{
        //                 result += "<tr>"
                        
        //                 result += `<td style='padding: 5px;'>${requests[r].fields[f].label}</td>`
        //                 result += `<td style='padding: 5px;'>${this.labels[requests[r].fields[f].anwer]}</td>`

        //                 if(requests[r].fields[f].observation){
        //                     result += `<td style='padding: 5px;'>${requests[r].fields[f].observation}</td>`
        //                 } else {
        //                     result += "<td style='padding: 5px;'></td>"
        //                 }

        //                 result += "</tr>"
        //                 // result += result
        //             })


        //             // result+=result
        //         })

        //         result += "</table>"

        //         console.log(result)

                

        //         fetch('https://consumos.foodservice.com.ar/api/enviomail', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify(
        //             {
        //                 from: "mifoodservice@foodservice.com.ar",
        //                 to: 'sswatowski@foodservice.com.ar',
        //                 subject: "Esto es una prueba con HTML!",
        //                 body: result,
        //             }
        //         ),
        //         })
        //         .then((response) => response.text())
        //         .then((data) => {
        //             console.log('Success:', data);
        //         })
        //         .catch((error) => {
        //             console.error('Error:', error);
        //         });
        //     })

        // },
        
        handleOperationSelection(operation){
            this.isInProgress = null
            this.operation = null
            this.zonal = null

            localStorage.setItem('selected_operation',operation.operation_id)

            this.$nextTick(()=>{
                this.operation = this.operations.find((o)=>o.id==operation.operation_id)

                getDataFrom(`https://aplicativos.firebaseio.com/zonal/${operation.operation_id}.json`).then((data)=>{
                    this.zonal = data

                    fetch(`https://bromatologia-fs.firebaseio.com/audits_in_progress/${this.operation.id}.json?shallow=true`)
                        .then((r)=>r.json())
                        .then((data)=>{
                            this.isInProgress = (data != null)
                            this.check_no_compliance()
                    })

                    fetch(`https://bromatologia-fs.firebaseio.com/audits_completed.json?orderBy="operation_id"&equalTo=${this.operation.id}&limitToLast=1`)
                        .then((r)=>r.json())
                        .then((data)=>{
                            this.last_audit = (data) ? data[Object.keys(data)[0]] : null
                    })
                })
            })
            
        },
        isAuditInProgress(){
            
        },
        getMapsURL(operation){
            let url = 'https://www.google.com/maps/';
            url += (operation.direccion) ? 'place/' + operation.direccion.replaceAll(' ', '+') : '';
            url += (operation.localidad) ? ',' + operation.localidad.replaceAll(" ","+") : '';
            url += (operation.coordenadas) ? '/@'+operation.coordenadas.replaceAll(" ","") : ''
            return url + ',17z'
        },


        greeting() {
            var hours = new Date().getHours();

            if (hours >= 0 && hours < 12) return "Buenos días!";
            if (hours >= 12 && hours < 20) return "Buenas tardes!";
            return "Buenas noches!";

        },
        name() {
            let u = JSON.parse(localStorage.getItem("userData"))
                .leg_nombre.split(" ")[0]
                .toLowerCase();
            return u[0].toUpperCase() + u.substr(1);
        },

        dateFormateR(d,la){
            console.log(la);
            console.log(d);
            return dateFormat(d)
        }
    },
};
</script>

<style scoped>
.wallet-card-section::before {
  background: #6236FF !important;
}

.blured {
    opacity: .6;
}
</style>
