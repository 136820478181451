import APP_DATA from './env'
export function getDataFrom(query,api){
    return new Promise((resolve)=>{
        let old_data = localStorage.getItem('__'+query);
        if(old_data!=null){
            let time = localStorage.getItem('__time__'+query);
            // console.log('tiem',getHoursDiff(Date.now(),parseInt(time)));
            if(time!=null && getHoursDiff(Date.now(),parseInt(time)) <= 1){
                console.info(`%c${query} obtenido desde localStorage`,'color:#0f0')
                resolve(JSON.parse(old_data))
            }
        }

        resolve(setDataFrom(query,api))
    })
}

export function dateFormat(date){
    return new Date(date).toLocaleString().substring(0,10)
}

export function setDataFrom(query,api){
    return new Promise((resolve,reject)=>{

        // Clean params
        query = (query.at(0)=="/")?query.substr(1):query

        let url = (api!=undefined) ? `${APP_DATA.db_url[APP_DATA.env]}/${query}.json` : query;

        fetch(url).then((r)=>r.json())
        .then((data)=>{
            localStorage.setItem('__'+query, JSON.stringify(data))
            localStorage.setItem('__time__'+query, Date.now())
            resolve(data)
        }).catch((e)=>{
            console.error(e)
            reject(e);
        })
    })
}

export function showError(err,msg){
    console.error(err)
    alert(msg)
}

import firebase from "firebase/compat/app";
import 'firebase/compat/storage';

export function putBase64(data) {
    return new Promise((resolve,reject)=>{
        var storageRef = firebase.storage().ref().child(`${APP_DATA.name.toLowerCase()}/images/${new Date().getTime()+'-'+Math.ceil(Math.random()*1000)}`);
        storageRef.putString(data, 'data_url').then(function(snapshot) {
            
            snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
                resolve(downloadURL);
            }).catch(e=>{reject(e);})

        });  
    })
}

function getHoursDiff(startDate, endDate) {
    let _start = new Date(startDate)
    let _end = new Date(endDate)

    const msInHour = 1000 * 60 * 60

    return Math.round(
        Math.abs(_end.getTime() - _start.getTime()) / msInHour,
    )
}

export function getTimeDiff(milis) {
    // 1- Convert to seconds:
    let seconds = milis / 1000;
    // 2- Extract hours:
    const hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    const minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    // seconds = seconds % 60;
    return {hours:hours,minutes:minutes,seconds:seconds % 60}
}
// TODO: desde aca
// msToHMS(Math.abs(1667234907704 - Date.now())) 

export function capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export function capitalizeEach(word) {
    let s = word.split(' ').flatMap((w) => {
        if (isEmpty(w)) return []
        return capitalize(w.trim())
    });
    return s.join(' ');
}

/**
 * Comprueba que el valor tenga valor y no sea null
 * @param property de un objeto
 * @returns true si es vacio
 */
 export function isEmpty(property){
    return property == null || property == '';
}
/**
 * Comprueba que la cadena dada no contenga nada mas que números
 * @param property un string a comprobar
 * @returns true si es numerico
 */
export function isNum(property){
    return /^\d+$/.test(property);
}

export function isset(e) {
    return e!=undefined && !isEmpty(e)
}

export function pad(value,len,char){
    let padding = ""
    for(let i = 0; i<=len; i++){
      padding+=char
    }
    
   return String(padding + value).slice(-len)
}

export function sort(items,prop_order,key_id){
    if (items==undefined) return []

    if(items instanceof Array){
        return items.sort((a,b)=>a[prop_order]-b[prop_order])
    } else {
        return Object.keys(items)
            .sort((a,b)=>items[a][prop_order]-items[b][prop_order])
            .map((k)=>{
                if(key_id){
                    return {id:k,...items[k]}
                } else {
                    return items[k]
                }
            })
    }
}

/**
 * set a default value if the object does not have the property
 * @param {Object} o object to set default property
 * @param {String} p property to set
 * @param {*} v any type of value to set as object property
 */
export function setdefault(o,p,v) {
    if(o==null) o = {}
    if(o[p] == undefined) o[p] = v
}