import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuditView from '../views/AuditView.vue'
import NonComplianceView from '../views/NonComplianceView.vue'
import LoginView from '../views/LoginView.vue'
// import APP_DATA from '@/env';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/auditoria/:id/:legajo',
    name: 'audit',
    component: AuditView
  },
  {
    path: '/non-compliance/:id',
    name: 'non_compliance',
    component: NonComplianceView
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !isAuthenticated()) next({ name: 'login' })
  else next()
})

function isAuthenticated() {
  return localStorage.getItem('userData') != null
}

export default router
