<template>
    <div class="pad mx-auto">
        <canvas id="sheet" width="400" height="400"></canvas>
        <button class="btn btn-outline-primary" id="eraser">
            <svg class="mr-1" width="16" height="16" fill="currentColor" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M290.7 57.4L57.4 290.7c-25 25-25 65.5 0 90.5l80 80c12 12 28.3 18.7 45.3 18.7H288h9.4H512c17.7 0 32-14.3 32-32s-14.3-32-32-32H387.9L518.6 285.3c25-25 25-65.5 0-90.5L381.3 57.4c-25-25-65.5-25-90.5 0zM297.4 416H288l-105.4 0-80-80L227.3 211.3 364.7 348.7 297.4 416z"/></svg>
            Volver a intentar
        </button>
    </div>
</template>

<script>
export default {
    name:'InputSignature',
    mounted(){
        var $vue = this

        var context = document.getElementById('sheet').getContext("2d");
        var canvas = document.getElementById('sheet');
        context = canvas.getContext("2d");
        context.strokeStyle = "#000000";
        context.lineJoin = "round";
        context.lineWidth = 3;

        var clickX = [];
        var clickY = [];
        var clickDrag = [];
        var paint;

        /**
         * Add information where the user clicked at.
         * @param {number} x
         * @param {number} y
         * @return {boolean} dragging
         */
        function addClick(x, y, dragging) {
            clickX.push(x);
            clickY.push(y);
            clickDrag.push(dragging);
        }

        /**
         * Redraw the complete canvas.
         */
        // eslint-disable-next-line no-unused-vars
        function redraw() {
            // Clears the canvas
            context.clearRect(0, 0, context.canvas.width, context.canvas.height);

            for (var i = 0; i < clickX.length; i += 1) {
                if (!clickDrag[i] && i == 0) {
                    context.beginPath();
                    context.moveTo(clickX[i], clickY[i]);
                    context.stroke();
                } else if (!clickDrag[i] && i > 0) {
                    context.closePath();

                    context.beginPath();
                    context.moveTo(clickX[i], clickY[i]);
                    context.stroke();
                } else {
                    context.lineTo(clickX[i], clickY[i]);
                    context.stroke();
                }
            }
        }

        /**
         * Draw the newly added point.
         * @return {void}
         */
        function drawNew() {
            var i = clickX.length - 1
            if (!clickDrag[i]) {
                if (clickX.length == 0) {
                    context.beginPath();
                    context.moveTo(clickX[i], clickY[i]);
                    context.stroke();
                } else {
                    context.closePath();

                    context.beginPath();
                    context.moveTo(clickX[i], clickY[i]);
                    context.stroke();
                }
            } else {
                context.lineTo(clickX[i], clickY[i]);
                context.stroke();
            }
        }

        function mouseDownEventHandler(e) {
            paint = true;
            var x = e.pageX - canvas.offsetLeft;
            var y = e.pageY - canvas.offsetTop;
            if (paint) {
                addClick(x, y, false);
                drawNew();
            }
        }

        function touchstartEventHandler(e) {
            paint = true;
            if (paint) {
                addClick(e.touches[0].pageX - canvas.offsetLeft, e.touches[0].pageY - canvas.offsetTop, false);
                drawNew();
            }
        }

        function mouseUpEventHandler() {
            context.closePath();
            paint = false;

            $vue.$emit('end',canvas.toDataURL())
        }

        function mouseMoveEventHandler(e) {
            var x = e.pageX - canvas.offsetLeft;
            var y = e.pageY - canvas.offsetTop;
            if (paint) {
                addClick(x, y, true);
                drawNew();
            }
        }

        function touchMoveEventHandler(e) {
            if (paint) {
                addClick(e.touches[0].pageX - canvas.offsetLeft, e.touches[0].pageY - canvas.offsetTop, true);
                drawNew();
            }
        }

        function setUpHandler(isMouseandNotTouch, detectEvent) {
            removeRaceHandlers();
            if (isMouseandNotTouch) {
                canvas.addEventListener('mouseup', mouseUpEventHandler);
                canvas.addEventListener('mousemove', mouseMoveEventHandler);
                canvas.addEventListener('mousedown', mouseDownEventHandler);
                mouseDownEventHandler(detectEvent);
            } else {
                canvas.addEventListener('touchstart', touchstartEventHandler);
                canvas.addEventListener('touchmove', touchMoveEventHandler);
                canvas.addEventListener('touchend', mouseUpEventHandler);
                touchstartEventHandler(detectEvent);
            }
        }

        function mouseWins(e) {
            setUpHandler(true, e);
        }

        function touchWins(e) {
            setUpHandler(false, e);
        }

        function removeRaceHandlers() {
            canvas.removeEventListener('mousedown', mouseWins);
            canvas.removeEventListener('touchstart', touchWins);
        }

        canvas.addEventListener('mousedown', mouseWins);
        canvas.addEventListener('touchstart', touchWins);

        document.getElementById('eraser').addEventListener('click',()=>{
            context.clearRect(0, 0, canvas.width, canvas.height);
            $vue.$emit('end',null)
        })
    }
}
</script>

<style>
    .pad {
        border:1px solid black;
        border-radius: 8px;
        width: 400px;
        height: 400px;

        background: #ffffff70;
        background-image: url('../assets/img/dot.png');
    }
</style>

