<template>
  <div class="d-flex-c-s pointer" style="width: max-content;" @click.prevent="$emit('change',!checked)">
    <div class="strong mr-1">{{label}}</div>
    <div class="dot rounded-circle" v-if="!checked"></div>
    <div class="dot rounded-circle" :class="'bg-'+color" v-else></div>
  </div>
</template>

<script>
export default {
    model: {
        prop: 'checked',
        event: 'change'
    },
    props:{
        checked:Boolean,
        color:String,
        label:String
    }
}
</script>

<style>
.dot {
    width:28px;
    height: 28px;
    background: #d9d9d9;
}
</style>