<template>
  <div class="splash-page mt-5 mb-5">
        <div class="iconbox mb-3">
            <svg v-if="!trigger.location" viewBox="0 0 24 24" width="36" height="36" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
            <svg v-else-if="!trigger.manager" viewBox="0 0 24 24" width="36" height="36" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
            <svg v-else viewBox="0 0 24 24" width="36" height="36" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
        </div>

        <h2 class="mb-2">¡Antes de empezar!</h2>

        <!-- ALERT SUCCES ubicacion y responsable -->
        <p class="alert alert-success mb-1" v-if="trigger.location">✓ Ubicación verificada</p>
        <p class="alert alert-success mb-1" v-if="trigger.manager">✓ Responsable asignado</p>
        
        <!-- MENSAJE SOBRE PASOS A COMPLETAR -->
        <p class="w-75 mx-auto text-dark mt-5"  v-if="!trigger.location">Vamos a verificar tu ubicación</p>
        <p class="w-75 mx-auto text-dark mt-5"  v-else-if="!trigger.manager">Agregá información del responsable en la operación</p>
        <p class="w-75 mx-auto text-dark mt-5"  v-else>Solicitá la firma de {{manager.name}}</p>

        <form v-if="trigger.location && !trigger.manager">
        <div class="card">
            <div class="card-body">
                <div class="form-group boxed">
                    <div class="input-wrapper">
                        <label class="label" for="name">Apellido y nombre*</label>
                        <input type="text" class="form-control" id="name" v-model="manager.name" placeholder="Luis Alberto" @input="delete error.manager['name']">
                        
                        <div class="text-muted text-danger text-left" v-if="error.manager['name']">&otimes; {{error.manager['name']}}</div>
                    </div>
                </div>
                <div class="form-group boxed">
                    <div class="input-wrapper">
                        <label class="label" for="dni">Documento de identidad*</label>
                        <input type="text" class="form-control" id="dni" v-model="manager.dni" placeholder="23456789" @input="delete error.manager['dni']">
                        
                        <div class="text-muted text-danger text-left" v-if="error.manager['dni']">&otimes; {{error.manager['dni']}}</div>
                    </div>
                </div>
                <div class="form-group boxed">
                    <div class="input-wrapper">
                        <label class="label" for="position">Puesto*</label>
                        <input type="text" class="form-control" id="position" v-model="manager.position" placeholder="Cocinero" @input="delete error.manager['position']">
                        
                        <div class="text-muted text-danger text-left" v-if="error.manager['position']">&otimes; {{error.manager['position']}}</div>
                    </div>
                </div>
            </div>
        </div>

        </form>

        <input-signature v-if="trigger.location && trigger.manager" @end="manager.signature = $event"></input-signature>
    </div>
    <div class="fixed-bar">
        <div class="row">
            <div class="col-6">
                <router-link to="/" class="btn btn-lg btn-outline-secondary btn-block">Cancelar</router-link>
            </div>
            <div class="col-6">
                <button @click.prevent="getGeoLocation()" class="btn btn-lg btn-primary btn-block" v-if="!trigger.location">Verificar ubicación</button>
                <button @click.prevent="setOperationManager()" class="btn btn-lg btn-primary btn-block" v-else-if="!trigger.manager">Agregar responsable</button>
                <button @click.prevent="setSignature()" class="btn btn-lg btn-primary btn-block" v-else-if="!trigger.signature" :disabled="!manager.signature">Todo listo</button>
                <button @click.prevent="$emit('ended',{location:location,...{manager:manager}})" class="btn btn-lg btn-success btn-block" :disabled="trigger.uploading" v-else>
                    <div class="spinner-border" style="width:12px;height:12px" role="status" v-if="trigger.uploading">
                        <span class="sr-only">Loading...</span>
                    </div>
                    &nbsp;
                    Iniciar auditoría
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import InputSignature from '@/components/InputSignature.vue'
import {isEmpty, isNum, putBase64} from '@/utils'

export default {
    name:'FirstStep',
    components: {InputSignature},
    data() {
        return {
            error:{manager:{}},



            location:null,
            manager:{
                name:null,
                dni:null,
                position:null,
                signature:null,
            },

            trigger:{
                location:false,
                manager:false,
                signature:false,
                uploading:false,
            }
        }
    },
    methods: {
        getGeoLocation(){
            setTimeout(() => {
                this.location = [-34.611179946978794, -58.5310257462623]
                this.trigger.location = true
            }, 500);

            navigator.geolocation.getCurrentPosition((pos)=>{
                this.location = [pos.coords.latitude,pos.coords.longitude]
                this.trigger.location = true

            }, (err)=>{
                console.warn(`ERROR(${err.code}): ${err.message}`)
            }, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            })
        },
        setOperationManager(){

            let values = ['name','dni','position']
            let valid = true

            values.forEach((value)=>{
                if(isEmpty(this.manager[value])){
                    valid=false
                    this.error.manager[value] = 'El campo es obligatorio'
                } else if(value == 'dni' && !isNum(this.manager[value])){
                    valid=false
                    this.error.manager[value] = 'Introduzca solo números por favor'
                }
            })

            if(!valid) return

            document.body.style.overflowY = 'hidden' 

            this.trigger.manager = true
        },
        // handleSignature(data){
        //     this.manager.signature = null
        //     if (data==null) {

        //         return
        //     }
        //     console.log(data);
        // },
        setSignature(){
            this.trigger.signature = true
            this.$nextTick(()=>{
                this.trigger.uploading = true
                putBase64(this.manager.signature).then((url)=>{
                    this.trigger.uploading = false
                    this.manager.signature = url
                    document.body.style.overflowY = 'scroll' 
                })
            })
        }
    },

}
</script>

<style>

</style>