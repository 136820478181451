<template>
    <div class="" v-if="trigger.list">
        <div class="card mb-2" :class="{'card-disabled':isDisabled(field.id)}" v-for="(field) in sorted(items)" :key="field.id">
            <div class="card-body">
                <div class="d-flex-s-b">
                    <div class="text-dark" @click="expandItem(field.id)">
                        {{field.label}}

                        <ul v-if="non_compliance_labels.length" class="pl-2">
                            <li v-for="(item,i) in non_compliance_labels" :key="i">
                                {{item.label}}

                                <ul class="pl-2">
                                    <li v-for="(field,f) in item.fields" :key="f">
                                        {{field.label}}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="ml-1">
                        <button class="btn btn-link p-1 text-dark" @click="expandItem(field.id)">
                            <svg viewBox="0 0 24 24" width="20" height="20" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                        </button>
                    </div>
                </div>
                <div class="d-flex-s-b mt-2" v-if="current_field == field.id">
                    <div>{{field.description}}</div>
                    <button class="btn btn-sm btn-secondary" :disabled="!field.extra_information" @click="trigger.showExtraInformation=true">
                        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                        &nbsp;
                        Instructivo
                    </button>
                </div>
                <div class="mt-2" :class="(field.conditional)?'d-flex-c-b':'d-flex-c-e'">
                    <div class="badge badge-danger" v-if="field.conditional">Condicional</div>
                    <div class="d-flex-c-e" v-if="!isDisabled(field.id)">
                        <RadioInput 
                            v-for="answer in answers" :key="answer.name"
                            class="p-1" 
                            :class="{'ml-5':(a!=0)}"

                            :checked="result[field.id][answer.name]" 
                            :label="answer.label"
                            :color="answer.color"

                            @click="handleAnswer(field.id,answer.name)" 
                        />
                    </div>
                    <div class="d-flex-c-e" v-else>
                        <RadioInput 
                            v-for="answer in answers" :key="answer.name"
                            class="p-1" 
                            :class="{'ml-5':(a!=0)}"

                            :checked="result[field.id][answer.name]" 
                            :label="answer.label"
                            :color="answer.color"
                        />
                    </div>
                </div>
                <div v-if="current_field == field.id">
                    <label>Observación</label>
                    <textarea rows="2" class="w-100 border rounded p-1" v-model="result[field.id].observation" @change="$emit('change',result)"></textarea>
                </div>
                <!-- <div class="text-dark">
                    {{field.label}}
                </div> -->
            </div>
        </div>


        <div class="modal fade modalbox show" id="Modal" tabindex="-1" style="display: block;" aria-modal="true" role="dialog" v-if="trigger.showExtraInformation">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Instructivo</h5>
                        <a href="#" data-bs-dismiss="modal" @click="trigger.showExtraInformation=false">Cerrar</a>
                    </div>
                    <div class="modal-body">
                        <img class="w-100" :src="items[current_field].extra_information">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { sort } from "@/utils";
import RadioInput from '@/components/Audit/RadioInput.vue'
import $ from "jquery";
export default {
    components:{RadioInput},
    props:{
        items:Object,
        initial:{type:Object,default:null},
        non:Object,
        is_zero:Number
    },
    emits:['change'],
    data() {
        return {
            result:{},
            current_field:null,

            answers:[
                {name:'approved',label:'Sí',color:'success'},
                {name:'disapproved',label:'No',color:'danger'},
                {name:'not_apply',label:'No aplica',color:'secondary'},
            ],

            non_compliance_labels: [],

            trigger:{list:false,conditional:false,showExtraInformation:false}
        }
    },
    mounted(){
        if(this.is_zero && this.non){
            // console.log('checkk',this.non);
            console.log(this.non)

            $.each(this.non,function(clave,valor){
              
              var epochTime = valor.timestamp; // ejemplo de tiempo epoch
              var date = new Date(epochTime);
              // Obtener los valores de día, mes y año de la fecha
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();

                // Crear una cadena de texto con el formato día/mes/año
                const dateString = `${day}/${month}/${year}`;
                console.log(dateString)
                $("#fecha_auditoria").html(dateString)
            })




            Object.keys(this.non).forEach((n)=>{
                Object.keys(this.non[n].requests).forEach((r)=>{
                    this.non_compliance_labels.push(this.non[n].requests[r])



                    // Object.keys(this.non[n].requests[r].fields).forEach((f)=>{
                    //     // console.log(this.non[n].requests[r][f].label)
                    //     // console.log(this.non[n].requests[r]);
                    //     this.non_compliance_labels.push(this.non[n].requests[r].fields[f].label)
                    // })
                })
            })
        }

        if(this.items && Object.keys(this.items).length){
            Object.keys(this.items).forEach((i)=>{
                console.log("INICIAL")
                
                if (this.initial!=null) {
                        if (this.initial[i] && this.initial[i].observation !== undefined) {
                            this.result[i] = {
                                 observation:this.initial[i].observation
                            }
                        }else{
                            this.result[i] = {
                                observation:""
                            }
                        }

                }else{
                    this.result[i] = {
                                observation:""
                            }
                }
                // Add answers
                this.answers.forEach((a)=>{
                    if (this.initial && this.initial[i] && this.initial[i][a.name]) {
                        this.result[i][a.name] = this.initial[i][a.name]
                    } else {
                        this.result[i][a.name] = false
                    }
                })
            })
        }

        this.trigger.list = true
    },
    methods: {
        sorted(items){
            return sort(items,'order',true)
        },
        handleAnswer(id,answer){
            this.answers.forEach((a)=>{
                if(a.name == answer) {
                    this.result[id][a.name] = !this.result[id][a.name]
                } else {
                    this.result[id][a.name] = false
                }
            })
            
            if(this.items[id].conditional){
                this.trigger.conditional = this.result[id]['disapproved']
                // if(answer=='disapproved') this.trigger.conditional = this.result[id]['disapproved']
                

                // console.log('se buscan condiconales');
                Object.keys(this.items).forEach((i)=>{
                    if(this.items[i].conditional_disabled){
                        if(this.result[id][answer] && answer=='disapproved'){
                            this.handleAnswer(i,'disapproved')
                        } else {
                            this.handleAnswer(i)
                        }

                    }
                })
            }

            // console.log(this.result);
            this.$emit('change',this.result)
        },
        isDisabled(id){
            if(!this.items[id].conditional_disabled) return false
            return this.trigger.conditional
        },
        expandItem(id){
            if(this.current_field==id) {
                this.current_field = null
                return
            }

            this.current_field = id

        }

    },
}
</script>

<style>
.card-disabled {
    opacity: .4;
}
</style>


