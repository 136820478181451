<template>
  <form>
        <div class="form-group boxed">
            <div class="input-wrapper">
                <label class="label">Operación seleccionada</label>
                <select class="form-control custom-select" :class="{'border-selected':(selected!=null)}" v-model="selected" @change="$emit('selected',operations[selected])">
                    <option :value="null" disabled>Seleccioná una operación</option>
                    <option v-for="(operation,id) in operations" :key="id" :value="id">{{operation.operation_name}}</option>
                </select>
            </div>
        </div>
    </form>
</template>

<script>
import {getDataFrom} from '@/utils'
export default {
    props:{
        initial:{
            type:Object,
            default:null
        },
    },
    data() {
        return {
            operations:null,
            selected:null,
        }
    },
    mounted(){
        let user = JSON.parse(localStorage.getItem('userData'))

        getDataFrom(`auditor_operation/${user.leg_numdoc}`, true).then((data)=>{
            this.operations = data

            if (this.initial!=null) {
                this.selected = this.initial.operation_id
            }
        })
        // https://bromatologia-5c2c2-default-rtdb.firebaseio.com/
    }
}
</script>

<style>
.border-selected {
    border: 2px solid #6236FF !important;
}
</style>